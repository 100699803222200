import React, { Component } from "react"
import { graphql } from "gatsby"
import { sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import FixedFacewallHomepage from "./index/FixedFacewallHomepage"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"
import ImageText from "./index/ImageText"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import FirstTime from "./index/FirstTime"
import InTheCommunity from "./index/InTheCommunity"
import ReferringProvider from "./index/ReferringProvider"
import LearnMoreCards from "./index/LearnMoreCards"
import Exparel from "./index/Exparel"
import ASIRD from "./index/ASIRD"
import StaticHero from "./index/StaticHero"
import TextTopSection from "./index/TextTopSection"
import Services from "./index/Services"
import StyleYourSmile from "./index/StyleYourSmile"
import ReviewCarousel from "../components/GoogleReviews/ReviewCarousel"
import { ProgramToast } from "../components/program-toast"
import Badge from "../components/GoogleReviews/Badge"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false
    }
  }

  render() {
    const { pageContext } = this.props
    let post
    const { language } = this.props.pageContext

    if (pageContext && pageContext.language) {
      switch (language) {
        case "en":
          post = this.props.data.allUniquePagesJson.nodes[0]
          break
        case "es":
          post = this.props.data.spanishIndex.nodes[0]

          break
        default:
          post = this.props.data.allUniquePagesJson.nodes[0]
      }
    } else {
      post = this.props.data.allUniquePagesJson.nodes[0]
    }

    const staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1
    }
    const sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    let allReviews = this.props.data.allReviews.nodes
    if (language === "es") allReviews = this.props.data.allReviewsEs.nodes
    const sortedReviews = sortReviewsByDate(allReviews)

    // shuffleArray(allReviews);
    const fixedFacewallReviews = sortedReviews.slice(0, 8)

    let schemaData = null
    if (language === "en") {
      schemaData = {
        presets: post.schemas.presets,
        customSchema: post.schemas.custom,
        dateModified: this.props.pageContext.dateModified,
        language,
        metaTitle: post.metaTitle,
        description: post.metaDescription,
        path: this.props.location.pathname
      }
    }

    return (
      <SharedStateProvider>
        <Layout
          className={`main-homepage ${language === "es" ? "es" : null}`}
          language={language}
          layoutClass="homepage-fab"
          noFab
          pageTitle="main-homepage">
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            schemaData={schemaData}
            pathname={this.props.location.pathname}
            lang={language}
          />

          <div className="static-container" style={staticStyle}>
            {post.hasSizzle ? (
              <Sizzle
                playing={this.state.playing}
                sizzleButtonStyle={sizzleButtonStyle}
                post={post}
                language={language}>
                <StaticHeading
                  playing={this.state.playing}
                  sizzleButtonStyle={sizzleButtonStyle}
                  post={post}
                  language={language}
                />
              </Sizzle>
            ) : (
              <StaticHero post={post} />
            )}
            {/* <Sizzle
              playing={this.state.playing}
              sizzleButtonStyle={sizzleButtonStyle}
              post={post}
              language={language}>
              <StaticHeading
                playing={this.state.playing}
                sizzleButtonStyle={sizzleButtonStyle}
                post={post}
                language={language}
              />
            </Sizzle> */}

            {/* <StaticHero post={post} /> */}

            <ImageText
              language={language}
              post={post}
              textImage
              noReverse
              colorBack
            />

            {post.homeRatingsReviews.hasThisSection && (
              <div className="home-g-reviews sctn color-back">
                <AllRatingsReviews
                  colorBack={false}
                  animation
                  heading={post.homeRatingsReviews.heading}
                  language={language}
                  buttonHref={post.homeRatingsReviews.buttonOne.href}
                  buttonText={post.homeRatingsReviews.buttonOne.buttonText}
                />
                <ReviewCarousel />

                <Badge url="https://www.google.com/search?q=braces+omaha&oq=braces+omaha&aqs=chrome.0.69i59j69i61l3.1897j0j9&sourceid=chrome&ie=UTF-8#lrd=0x8793f3cd215abfe9:0xefe79a8a342a2136,1,,," />
              </div>
            )}

            <TextTopSection post={post} />

            <Services language={language} post={post.services} />

            {/* <LearnMoreCards language={language} post={post.learnMoreCards} /> */}

            <StyleYourSmile
              heading={post.styleYourSmile.heading}
              text={post.styleYourSmile.text}
              buttons={post.styleYourSmile.buttons}
              hasThisSection
              logo={post.styleYourSmile.logo}
            />

            <FixedFacewallHomepage
              language={language}
              heading={
                language === "es"
                  ? "Escuche Acerca de Nuestros Pacientes"
                  : "Hear From Our Patients"
              }
              reviews={fixedFacewallReviews}
            />

            {/* <HeadingVideo post={post} /> */}

            {/* <AAAHC post={post.homeAAAHC} language={language}/> */}

            <InTheCommunity
              colorBack
              language={language}
              data={post.inTheCommunity}
            />

            <ReferringProvider
              colorBack={false}
              language={language}
              data={post.referringProvider}
            />

            <Exparel post={post.homeExparel} />
            <ASIRD post={post.homeAsird} />

            <FirstTime colorBack language={language} post={post} noReverse />
          </div>

          {post.toast.hasThisSection && (
            <ProgramToast closeDate={post.toast.closeDate}>
              {post.toast.text}
            </ProgramToast>
          )}
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query reviewsIndexQuery {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { ne: "ESP" } }
    ) {
      nodes {
        ...FacewallData
      }
    }
    allReviewsEs: allSpanishReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ESP" } }
      limit: 4
    ) {
      nodes {
        title
        reviewerName
        reviewType
        reviewLanguage
        thumbnailPublicId
        mainProcedureDone
        monthYear {
          month
          year
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        schemas {
          presets
          custom
        }
        hasSizzle
        mobileHeading
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
        }
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
              youtube
            }
          }
          heading
          imageId
          text
        }
        homeSectionTwo {
          heading
          text
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          logos {
            imageId
          }
        }
        services {
          blurb
          hasThisSection
          heading
          items {
            blurb
            heading
            imageId
          }
          buttons {
            button {
              buttonText
              appearance
              href
              destination
            }
          }
        }
        styleYourSmile {
          heading
          text
          logo
          buttons {
            button {
              buttonText
              destination
              href
              appearance
            }
          }
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          logos {
            imageId
          }
          button {
            useButton
            buttonText
            href
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        seoHeading
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        toast {
          hasThisSection
          text
          closeDate
        }
      }
    }
    spanishIndex: allSpanishUniquePagesJson(filter: { title: { eq: "/es/" } }) {
      nodes {
        hasSizzle
        mobileHeading
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
        }
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          logos {
            imageId
          }
          button {
            useButton
            buttonText
            href
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        seoHeading
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        toast {
          hasThisSection
          text
          closeDate
        }
      }
    }
  }
`

export default IndexPage
