import React from "react"
import classNames from "classnames"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import BracesVideo from "../../videos/wlds-braces-video.mp4"
import Video from "../../components/Video/Video"

function StyleYourSmile({
  heading,
  text,
  buttons,
  logo,
  hasThisSection,
  colorBack
}) {
  if (!hasThisSection) return <></>

  const mainClasses = classNames({
    "style-your-smile sctn columns mb-0-mobile is-vcentered": true,
    "color-back": colorBack
  })

  return (
    <>
      <div className="multi-color-hl" />
      <div className={mainClasses}>
        <div className="column is-3" />
        <div className="column" data-aos="fade-right" data-aos-duration="1200">
          <ImageMeta
            className="style-your-smile-logo"
            cloudname="nuvolum"
            publicId={logo}
            width="auto"
          />
          <h3 className="mt-1">{heading}</h3>
          <MarkdownViewer markdown={text} />
          <ButtonGroupMap buttons={buttons} />
        </div>
        <div className="column is-1" />
        <div
          className="column mt-2-mobile"
          data-aos="fade-left"
          data-aos-duration="1200">
          <Video src={BracesVideo} />
        </div>
        <div className="column is-3" />
      </div>
      <div className="multi-color-hl" />
    </>
  )
}

export default StyleYourSmile
