import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"

const TextTopSection = props => {
  const logos = props.post.homeSectionTwo.logos

  const logoSize = logos.length <= 3 ? "logo-lg" : "logo-sm"

  return (
    <section
      className="sctn text-top-section"
      data-aos="fade-up"
      data-aos-duration="1200">
      <div className="columns">
        <div className="column is-4"></div>

        <div className="column">
          <div className="columns">
            <div className="column">
              <MarkdownViewer
                className="has-text-centered"
                markdown={props.post.homeSectionTwo.heading}
              />
              <MarkdownViewer markdown={props.post.homeSectionTwo.text} />
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className={`logo-group ${logoSize}`}>
                {logos.map((logo, i) => {
                  return (
                    <ImageMeta
                      key={i}
                      cloudName="nuvolum"
                      publicId={logo.imageId}
                      width="auto"
                      responsive
                    />
                  )
                })}
              </div>
            </div>
          </div>

          {props.post.homeSectionTwo.buttons[0].button.href && (
            <div className="columns">
              <div className="column">
                <ButtonGroupMap
                  buttons={props.post.homeSectionTwo.buttons}
                  isCentered
                />
              </div>
            </div>
          )}


        </div>
        <div className="column is-4"></div>
      </div>
    </section>
  )
}

export default TextTopSection
