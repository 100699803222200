import React from "react"
import "../../components/animations/scroll.scss"
import TheaterVideo from "@components/Theater/TheaterVideo"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

function StaticHeading(props) {
  let dyStart = -2.75
  let dyStartMobile = -3.5
  // if (props.post.svgHeading.length === 4) {
  //   dyStart = -3.5;
  // }
  return (
    <div
      style={{
        textAlign: "left"
      }}
      className="static-heading">
      <div className="mobile-headline">
        <h2 className="h1-style">{props.post.mobileHeading}</h2>
      </div>
      <div
        className="above-text-container"
        style={{ opacity: "1", transition: "unset" }}>
        {props.post.svgHeading.map((heading, i) => (
          <span
            role="heading"
            className="curtain-heading h3-style is-hidden-mobile"
            key={heading}
            style={{ textTransform: "uppercase" }}>
            {heading}
            <br />
          </span>
        ))}
        <span
          role="heading"
          className="curtain-heading h3-style is-hidden-tablet"
          dangerouslySetInnerHTML={{
            __html: props.post.svgHeading.join(" ").toUpperCase()
          }}
        />
        <h1
          className="SEOTitle"
          dangerouslySetInnerHTML={{ __html: props.post.seoHeading }}
        />
      </div>

      {props.post.sizzleYoutube && (
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${props.post.sizzleYoutube}`}
          controls={true}
          playing={true}
          onlyButton={true}
          sizzleButton={true}
          buttonClass="sizzle-button-under contained"
          compId={"sizzleTheaterButton"}
          language={props.language}
        />
      )}
    </div>
  )
}

export default StaticHeading
