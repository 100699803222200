import React from "react"

const Video = ({ src }) => (
  <div
    className="video"
    dangerouslySetInnerHTML={{
      __html: `
            <video
              class="block"
              autoplay
              loop
              muted
              playsinline
              width="100%"
            >
              <source src="${src}" type="video/mp4" />
            </video>`
    }}
  />
)

export default Video
